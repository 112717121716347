<template>
	<div>
		<a-modal v-model:visible="visible" width="800px" @cancel="close">
			<template #title>
				<modalHeader title="盘盈入库"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">取消</a-button>
				<a-button v-permission="['pc_inventory_profitManage_query']" @click="onSubmit" type="primary"> 确认入库 </a-button>
			</template>
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="formModel" :label-col="{ span: 7 }">
					<a-row>
						<a-col :span="12">
							<a-form-item label="资产编号" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.code" placeholder="请输入资产编号"></a-input> -->
								{{formModel.code}}
							</a-form-item>
							<a-form-item label="资产名称" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.name" placeholder="请输入资产名称"></a-input> -->
								{{formModel.name}}
							</a-form-item>
							<a-form-item label="取得日期" class="ui-form__item">
								<!-- <a-date-picker  v-model:value="formModel.gainTime" disabled></a-date-picker> -->
								{{transDateTime(formModel.gainTime)}}
							</a-form-item>
							<a-form-item label="设备用途" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.useType" placeholder="请选择设备用途" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.usageList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<!-- {{formModel.useType}} -->
								<div v-if="formModel.useType === 1">办公</div>
								<div v-if="formModel.useType === 2">教学</div>
								<div v-if="formModel.useType === 3">培训</div>
								<div v-if="formModel.useType === 4">执法</div>
								<div v-if="formModel.useType === 5">窗口</div>
								<div v-if="formModel.useType === 6">专用</div>
								<div v-if="formModel.useType === 7">其他</div>
								<div v-if="formModel.useType === 8">医疗</div>
								<div v-if="formModel.useType === 9">科研</div>
							</a-form-item>
							<a-form-item label="规格型号" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.model" placeholder="请输入规格型号"></a-input> -->
								{{formModel.model}}
							</a-form-item>
							<a-form-item label="使用部门" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.departmentId" placeholder="请选择使用部门" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								{{formModel.departmentName}}
							</a-form-item>
							<a-form-item label="使用人" class="ui-form__item">
								<!-- <a-input v-model:value="formModel." placeholder="请输入使用人"></a-input> -->
								<!-- <a-select v-model:value="formModel.userId" allow-clear placeholder="请选择使用人" style="width: 190px;" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
								</a-select> -->
								{{formModel.user}}
							</a-form-item>
							<a-form-item label="保存地点" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.place" placeholder="请输入保存地点"></a-input> -->
								{{formModel.place}}
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="资产分类" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.assetsParameterId" placeholder="请选择资产分类" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								{{formModel.classifyName}}
							</a-form-item>
							<a-form-item label="取得方式" class="ui-form__item">
								<!-- {{formModel.gainType}} -->
								<div v-if="formModel.gainType === 1">新购</div>
								<div v-if="formModel.gainType === 2">挑拨</div>
								<div v-if="formModel.gainType === 3">接收捐献</div>
								<div v-if="formModel.gainType === 4">自建</div>
								<div v-if="formModel.gainType === 5">置换</div>
								<div v-if="formModel.gainType === 6">盘盈</div>
								<div v-if="formModel.gainType === 7">自行研制</div>
								<div v-if="formModel.gainType === 8">其他</div>
							</a-form-item>
							<!-- <a-form-item label="编制情况" class="ui-form__item">
								<a-select v-model:value="formModel." placeholder="请选择编制情况">
									<a-select-option :value="0">在编</a-select-option>
								</a-select>
							</a-form-item> -->
							<a-form-item label="使用状况" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.conditionType" placeholder="请选择使用状况" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								<!-- {{formModel.conditionType}} -->
								<div v-if="formModel.conditionType === 1">在用</div>
								<div v-if="formModel.conditionType === 2">出租</div>
								<div v-if="formModel.conditionType === 3">出借</div>
								<div v-if="formModel.conditionType === 4">闲置</div>
								<div v-if="formModel.conditionType === 5">待处置</div>
							</a-form-item>
							<a-form-item label="品牌" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.brand" placeholder="请输入品牌"></a-input> -->
								{{formModel.brand}}
							</a-form-item>
							<a-form-item label="价值" class="ui-form__item">
								<!-- <a-input-number v-model:value="formModel.amount" placeholder="请输入" :min="0" :precision="2"></a-input-number> 元 -->
								{{formModel.amount}}元
							</a-form-item>
							<a-form-item label="资产归属" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.nature" placeholder="请选择资产归属" disabled>
									<a-select-option :value="'1'">单位</a-select-option>
									<a-select-option :value="'2'">个人</a-select-option>
									<a-select-option :value="'3'">租借</a-select-option>
								</a-select> -->
								{{formModel.nature == 1?'单位':(formModel.nature == 2?'个人':'租借')}}
							</a-form-item>
							<a-form-item label="使用单位" class="ui-form__item">
								<!-- <a-select v-model:value="formModel.unitId" placeholder="请选择使用部门" disabled>
									<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
								</a-select> -->
								{{formModel.unitName}}
							</a-form-item>
							<a-form-item label="盘点人" class="ui-form__item">
								<!-- <a-input v-model:value="formModel.brand" placeholder="请输入品牌"></a-input> -->
								{{formModel.checker}}
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item label="备注" class="ui-form__item" :label-col="{ span: 3 }">
								<!-- <a-input v-model:value="formModel.description" placeholder="请输入备注信息"></a-input> -->
								{{formModel.description}}
							</a-form-item>
						</a-col>
						<a-col :span="24" style="padding-left: 12px;">
							<a-form-item label="设备照片" class="ui-form__item" :label-col="{ span: 3 }">
								<a-image :width="100" v-for="(item,index) in list" :key="index"  :src="item"></a-image>
								<!-- <imageUpload v-else v-model:value="list" :limit="5" :maxSize="2"></imageUpload> -->
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { inventoryProfit, lossOrProfitDetail, postAudit } from '@/service/modules/inventory.js';
	// import { getAssetsDetailSearch } from '@/service/modules/property.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import imageUpload from '@/components/upload/imageUpload.vue';
	export default {
		components: { Icon, imageUpload, modalHeader },
		data() {
			return {
				loading: false,
				visible: false,
				isEdit: false,
				formModel: {},
				// tempDate: 0,
				assetsId: null, // 资产操作ID
				list: []
			}
		},
		methods: {
			async getDetailData(id) {
				this.loading = true;
				try {
					let ret = await lossOrProfitDetail({
						id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.formModel = ret.data.assets;
						this.formModel.checker = ret.data.checker;
						// this.showFormModal.imgList = []
						let tempStr = ret.data.assets.imgUrl
						let tempArr = tempStr? tempStr.split(','): []
						this.list.push(...tempArr)
						let tempReturnTime = ret.data.assets.gainTime
						this.formModel.gainTime = this.moment(tempReturnTime)
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				// this.$refs.formRef.validate().then(async () => {
				// 	let postData = JSON.parse(JSON.stringify(this.formModal));
					this.loading = true;
					try {
						let ret = await postAudit({
							id: this.assetsId
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success("入库成功");
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
			},
			open(e) {
				if (e) {
					this.isEdit = true
					this.getDetailData(e.assetsRecordId || e.skipId)
					this.assetsId = e.assetsRecordId || e.skipId
				} else {
					this.isEdit = false
					// this.showFormModal = {}
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.formModal = {}
				this.list = []
				this.isEdit = false
				this.visible = false;
			},
			// onSelDate(dateString){
			// 	console.log('newval',dateString)
			// 	let temp = this.transDateTime(dateString)
			// 	let tempTime = new Date(temp)
			// 	console.log('newval2',tempTime)
			// 	this.formModal.returnTime = tempTime.valueOf()
			// },
		}
		// watch: {
		// 	tempDate(newVal,oldVal) {
		// 		console.log('newval',newVal)
		// 		let temp = this.transDateTime(newVal)
		// 		let tempTime = new Date(temp)
		// 		this.formModal.returnTime = tempTime.valueOf()
		// 	}
		// }
	}
</script>

<style scoped>
	
</style>
<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_inventory_profitManage_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" style="margin-right: 16px;" v-permission="['pc_inventory_profitManage_export']" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<!-- <a-button v-permission="['pc_inventory_profitManage_export']">
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button>
					<a-button v-permission="['pc_inventory_profitManage_export']">
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button> -->
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="资产编号" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input-number style="width: 200px" placeholder="请输入资产编号数字部分" v-model:value="formModal.startCode"/>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input-number style="width: 200px" placeholder="请输入资产编号数字部分" v-model:value="formModal.endCode"/>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="价值区间" class="ui-form__item">
						<a-row>
							<a-col :span="11">
								<a-input-number style="width: 150px" placeholder="请输入价值" v-model:value="formModal.startPrice"/>
							</a-col>
							<a-col :span="2" class="ui-form__middle">到</a-col>
							<a-col :span="11">
								<a-input-number style="width: 150px" placeholder="请输入价值" v-model:value="formModal.endPrice"/>
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item label="资产名称" class="ui-form__item">
						<a-input placeholder="请输入资产名称" v-model:value="formModal.name"></a-input>
					</a-form-item>
					<a-form-item label="资产分类" class="ui-form__item">
						<a-cascader v-model:value="formModal.typeId" :options="$store.state.count.assetClassify" placeholder="请选择资产分类" />
						<!-- <a-select v-model:value="formModal.typeId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.assetClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>
					<a-form-item label="盘点任务名称" class="ui-form__item">
						<a-input placeholder="请输入盘点任务名称" v-model:value="formModal.taskName"></a-input>
					</a-form-item>
					<a-form-item label="是否已入库" class="ui-form__item">
						<a-select placeholder="请选择是否已入库" v-model:value="formModal.isAffirm" allow-clear style="width: 190px;">
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
					<!-- <a-form-item label="使用部门" class="ui-form__item">
						<a-select v-model:value="formModal.depId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="使用人" class="ui-form__item">
						<a-select v-model:value="formModal.userId" allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.username }}</a-select-option>
						</a-select>
					</a-form-item> -->
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'unitName'">单位名称</a-checkbox>
							<a-checkbox :value="'taskName'">盘点任务名称</a-checkbox>
							<a-checkbox :value="'classifyName'">资产分类</a-checkbox>
							<a-checkbox :value="'a.code'">资产编号</a-checkbox>
							<a-checkbox :value="'assetsName'">资产名称</a-checkbox>
							<a-checkbox :value="'amount'">价值</a-checkbox>
							<a-checkbox :value="'state'">是否已入库</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 0" type="link" size="small" @click="onProfit(record)">入库申请</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 0" type="link" size="small" @click="onProfit(record)">入库申请</a-button>
							<div v-else>-</div>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<profitModal ref="profitModalRef" @refresh="onReset"></profitModal>
	</div>
</template>

<script>
	import { getProfitList } from '@/service/modules/inventory.js';
	import { Icon } from '@/components/icon/icon.js';
	import profitModal from '@/views/inventoryManage/components/profitModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, profitModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {},
				getDataFun: getProfitList,
				list: [],
				type: 'inventoryProfitStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
					
				}, {
					title: '单位名称',
					align: 'center',
					dataIndex: "unitName"
				}, {
					title: '盘点任务名称',
					align: 'center',
					dataIndex: "taskName"
				}, {
					title: '资产分类',
					align: 'center',
					dataIndex: "classifyName"
				}, {
					title: '资产编号',
					align: 'center',
					dataIndex: "code"
				}, {
					title: '资产名称',
					align: 'center',
					dataIndex: "assetsName"
				}, {
					title: '价值',
					align: 'center',
					dataIndex: "amount"
				}, {
					title: '是否已入库',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				}, {
					title: '入库申请人',
					align: 'center',
					dataIndex: "createUser"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				}
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getProfitList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("profitLis",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				this.searchData.assetsParameterId = this.searchData.typeId ? this.searchData.typeId[this.searchData.typeId.length - 1] : undefined;
				this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onProfit(item) {
				this.$refs.profitModalRef.open(item)
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		padding-left: 5px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>